"use client";
import { useMemo, useState } from "react";

import { useLocale } from "next-intl";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import SanityImage from "@/components/atoms/SanityImage";

import generateImgSizes from "@/utils/generateImgSizes";
import { Link } from "@/utils/navigation";

export type Slide = {
  title: string;
  subtitle: string;
  image: string;
  url: string;
};

type HeroSectionSliderProps = {
  legacy?: boolean;
  slides: Slide[];
};

const HeroSectionSlider = ({ slides, legacy }: HeroSectionSliderProps) => {
  const [swipe, setSwipe] = useState<any>();

  const locale = useLocale();

  const pagination = {
    clickable: true,
    renderBullet: (index: number, className: string) => {
      return `<span class="${className}"></span>`;
    },
  };

  const SliderItems = useMemo(() => {
    return slides.map((slide, i: number) => {
      const url = slide?.url
        ? slide.url.replace(`https://www.efarmz.be/${locale}`, ``)
        : `#`;

      return (
        <SwiperSlide
          className="aspect-w-2 aspect-h-2 sm:aspect-h-1"
          key={i.toString()}
        >
          <div className="flex flex-col">
            <div className="grow relative h-20 w-full">
              <SanityImage
                alt=""
                image={slide.image}
                className="object-cover bg-blue-50"
                fill
                priority={i === 0}
                sizes={generateImgSizes({
                  default: `824px`,
                  sm: `380px`,
                  md: `450px`,
                  xs: `345px`,
                })}
              />
            </div>
            <div className="flex items-end p-6 bg-white">
              <div>
                <h3 className="font-semibold text-terreau-500 text-md md:text-xl">
                  <Link href={url as any}>
                    <span className="absolute inset-0" />
                    {slide?.title}
                  </Link>
                </h3>
                <p
                  aria-hidden="true"
                  className="mt-1 text-sm md:text-lg text-vert-500 font-medium"
                >
                  {slide?.subtitle}
                </p>
              </div>
            </div>
          </div>
        </SwiperSlide>
      );
    });
  }, []);
  return (
    <Swiper
      //  spaceBetween={50}
      slidesPerView={1}
      pagination={pagination}
      modules={[Autoplay, Pagination]}
      autoplay={{
        delay: 5000,
        disableOnInteraction: false,
      }}
      onInit={(swiper: any) => {
        setSwipe(swiper);
      }}
      className="!h-full"
      cssMode
    >
      {SliderItems}
    </Swiper>
  );
};

export default HeroSectionSlider;
