const screens = {
  xs: `375px`,
  sm: `640px`,
  // => @media (min-width: 640px) { ... }

  md: `768px`,
  // => @media (min-width: 768px) { ... }

  lg: `1024px`,
  // => @media (min-width: 1024px) { ... }

  xl: `1280px`,
  // => @media (min-width: 1280px) { ... }
};

type Size = {
  default: string;
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
  xl?: string;
};

const generateImgSizes = (size: Size) => {
  let sizeString = ``;

  if (size.xs) {
    sizeString += `(max-width: ${screens.xs}) ${size.xs}, `;
  }

  if (size.sm) {
    sizeString += `(max-width: ${screens.sm}) ${size.sm}, `;
  }

  if (size.md) {
    sizeString += `(max-width: ${screens.md}) ${size.md}, `;
  }

  if (size.lg) {
    sizeString += `(max-width: ${screens.lg}) ${size.lg}, `;
  }
  if (size.xl) {
    sizeString += `(max-width: ${screens.xl}) ${size.xl} `;
  }
  sizeString += size.default;

  return sizeString;
};

export default generateImgSizes;
