"use client";

import HomeContainer from "@/components/containers/HomeContainer";
import { usePreview } from "@/utils/sanity.preview";

type ContainerProps = {
  query: any;
};

const Container = ({ query }: ContainerProps) => {
  const data = usePreview(null, query, {});

  return <HomeContainer sanityData={data[0]} legacy />;
};

export default Container;
