import Container from "@efarmz/efarmz-react-commons/esm/components/atoms/Container";

import { HeroSectionSkeleton } from "@/components/organisms/HeroSection";

import { SanityProductTagSectionSkeleton } from "@/components/sanity/SanityProductTagSection";

const HomeContainerSkeleton = () => {
  return (
    <>
      <Container>
        <HeroSectionSkeleton />
      </Container>

      <Container className={`py-5 sm:py-10`}>
        <SanityProductTagSectionSkeleton />
      </Container>
    </>
  );
};

export default HomeContainerSkeleton;
