"use client";

import { useSearchParams } from "next/navigation";
import { PreviewSuspense } from "next-sanity/preview";

import Preview from "../preview";

import HomeContainer from "@/components/containers/HomeContainer";

type ContainerProps = {
  sanityData: any;
  query: any;
};

const Container = ({ sanityData, query }: ContainerProps) => {
  const searchParams = useSearchParams();

  if (searchParams?.has(`preview`)) {
    return (
      <PreviewSuspense fallback={<div />}>
        <Preview query={query} />
      </PreviewSuspense>
    );
  }

  return <HomeContainer sanityData={sanityData} legacy={false} />;
};

export default Container;
