"use client";

import { Suspense } from "react";

import Container from "@efarmz/efarmz-react-commons/esm/components/atoms/Container";

import HeroSection from "@/components/organisms/HeroSection";
import UserOnboarding from "@/components/organisms/UserOnboarding";

import useAuth from "@/hooks/useAuth";

import { HomeContainerSkeleton } from ".";

import SanityLayoutBuilder from "@/components/sanity/SanityLayoutBuilder";

type HomeContainerProps = {
  sanityData: any;
  legacy?: boolean;
};

const HomeContainer = ({ sanityData, legacy = false }: HomeContainerProps) => {
  const { status } = useAuth();

  if (status === `loading`) {
    return <HomeContainerSkeleton />;
  }
  return (
    <>
      <UserOnboarding />
      <Container>
        <HeroSection sanityData={sanityData} />
      </Container>
      {/*
      <Container legacy={legacy}>
        <div className="pt-8 lg:pt-16 grid grid-cols-2 lg:grid-cols-none lg:grid-flow-col lg:auto-cols-auto gap-x-8 lg:gap-y-10">
          {sanityData?.benefits?.map((benefit: any, i: number) => (
            <div key={i.toString()} className="sm:flex lg:block">
              <div className="lg:flex-shrink-0 hidden lg:flex lg:justify-center">
                <CheckBadgeIcon
                  className="h-10 w-10 flex-none text-choux-500"
                  aria-hidden="true"
                />
              </div>
              <div className="mt-4 lg:ml-0 lg:mt-6 lg:text-center">
                <div className="flex items-center lg:justify-center">
                  <CheckBadgeIcon
                    className="h-8 w-8 flex-none text-choux-500 mr-2 lg:hidden"
                    aria-hidden="true"
                  />
                  <h3 className="text-sm lg:text-md font-medium text-vert-500 whitespace-normal">
                    {benefit.title}
                  </h3>
                </div>

                <p className="mt-2 text-sm text-gray-500 lg:text-center hidden md:block">
                  {benefit.subtitle}
                </p>
              </div>
            </div>
          ))}
        </div>
      </Container>
          */}
      <Suspense fallback={<div />}>
        <SanityLayoutBuilder sections={sanityData?.sections} legacy={legacy} />
      </Suspense>
    </>
  );
};

export default HomeContainer;
