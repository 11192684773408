const HeroSectionSkeleton = () => {
  return (
    <div className="mt-6 space-y-2 sm:space-y-0 gap-x-2 gap-y-2 sm:grid sm:grid-cols-3 sm:gap-y-6 sm:grid-flow-col sm:grid-rows-2 sm:gap-x-6 lg:gap-8">
      <div className="col-span-2  sm:block group overflow-hidden rounded-lg sm:row-span-2">
        <div className="h-[411px] bg-white animate-pulse" />
      </div>
      <div className="group aspect-w-3 sm:aspect-w-2 aspect-h-1 overflow-hidden rounded-lg sm:aspect-none sm:relative sm:h-full relative">
        <div
          aria-hidden="true"
          className="opacity-90 sm:absolute sm:inset-0 bg-anis-500"
        />
        <div className="flex flex-col justify-center items-center p-6 sm:absolute sm:inset-0">
          <h3 className="font-semibold h-6  text-white text-xl md:text-2xl mt-3">
            <span
              className={`h-4 w-36 block bg-gray-200 rounded-md animate-pulse`}
            />
          </h3>
          <p
            aria-hidden="true"
            className="mt-1 h-5 text-md md:text-lg text-white"
          >
            <span
              className={`h-3 w-44 block bg-gray-200 rounded-md animate-pulse`}
            />
          </p>
          <p
            aria-hidden="true"
            className="mt-1 h-5 text-md md:text-lg text-white"
          >
            <span
              className={`h-3 w-60 block bg-gray-200 rounded-md animate-pulse`}
            />
          </p>
        </div>
      </div>
      <div className="sm:mt-0 group aspect-w-3 sm:aspect-w-2 aspect-h-1 overflow-hidden rounded-lg sm:aspect-none sm:relative sm:h-full">
        <div
          aria-hidden="true"
          className="opacity-90 sm:absolute sm:inset-0 bg-choux-500"
        />
        <div className="flex flex-col justify-center items-center p-6 sm:absolute sm:inset-0">
          <h3 className="font-semibold h-6  text-white text-xl md:text-2xl mt-3">
            <span
              className={`h-4 w-36 block bg-gray-200 rounded-md animate-pulse`}
            />
          </h3>
          <p
            aria-hidden="true"
            className="mt-1 h-5 text-md md:text-lg text-white"
          >
            <span
              className={`h-3 w-44 block bg-gray-200 rounded-md animate-pulse`}
            />
          </p>
          <p
            aria-hidden="true"
            className="mt-1 h-5 text-md md:text-lg text-white"
          >
            <span
              className={`h-3 w-60 block bg-gray-200 rounded-md animate-pulse`}
            />
          </p>
        </div>
      </div>
    </div>
  );
};

export default HeroSectionSkeleton;
