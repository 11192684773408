import { useEffect, useState } from "react";

import {
  LockClosedIcon,
  ShoppingCartIcon,
  TruckIcon,
} from "@heroicons/react/20/solid";
import { useRouter, useSearchParams } from "next/navigation";
import { SwiperSlide } from "swiper/react";

import useDialog from "@efarmz/efarmz-react-commons/esm/hooks/useDialog";

import Slider from "@/components/atoms/Slider";

import useAuth from "@/hooks/useAuth";
import useSegment from "@/hooks/useSegment";

import Image1 from "./Image1";

import { usePathname } from "@/utils/navigation";

const features = [
  {
    name: `Découvrez les merveilles de nos producteurs locaux`,
    icon: LockClosedIcon,
  },
  {
    name: `Commandez selon vos habitudes et vos envies`,
    icon: ShoppingCartIcon,
  },
  {
    name: `Votre commande directement chez vous ou à un point de retrait`,
    icon: TruckIcon,
  },
];

const UserOnboarding = () => {
  const [showWelcomeOnboarding, setShowWelcomeOnboarding] = useState(false);
  const [hasBeenTriggered, setHasBeenTriggered] = useState(false);
  const searchParams = useSearchParams();
  const pathname = usePathname();
  const router = useRouter();
  const segment = useSegment();
  const { user, status } = useAuth();

  const isOnboarding = searchParams.has(`welcome`);

  useEffect(() => {
    if (isOnboarding && status !== `loading` && user?.id && !hasBeenTriggered) {
      const provider = searchParams.get(`provider`);
      const queryString = searchParams.toString();
      const queries = queryString
        .split(`&`)
        .filter(
          (query) => !query.includes(`welcome`) && !query.includes(`provider`)
        );
      segment.track(`UserSignedUp`, {
        provider: provider,
        userId: user?.id,
      });
      const path = `${pathname}?${queries.join(`&`)}`;
      router.replace(path);
      setHasBeenTriggered(true);
      //setShowWelcomeOnboarding(true);
    }
  }, [
    hasBeenTriggered,
    isOnboarding,
    pathname,
    router,
    searchParams,
    segment,
    status,
    user?.id,
  ]);

  const slides = [
    {
      title: `Nos box repas bio 1`,
      subtitle: `Découvrez nos box repas bio pour préparer simplement 3 à 5 plats équilibrés et de saison. Ingrédients en quantités exactes et recettes incluses.`,
      image: <Image1 className="max-w-[200px]" />,
    },
    {
      title: `Nos box repas bio 2`,
      subtitle: `Découvrez nos box repas bio pour préparer simplement 3 à 5 plats équilibrés et de saison. Ingrédients en quantités exactes et recettes incluses.`,
      image: <Image1 className="max-w-[200px]" />,
    },
  ];

  const Slides = slides.map((slide, i) => {
    return (
      <SwiperSlide key={i.toString()}>
        <div className="flex flex-col py-12 items-center w-full text-center">
          {slide.image}
          <h3 className="text-4xl font-semibold leading-7 mt-5 font-secondary text-vert-500">
            {slide.title}
          </h3>
          <p className="text-base mt-3 text-menthe-500 font-medium">
            {slide.subtitle}
          </p>
        </div>
      </SwiperSlide>
    );
  });
  const [Dialog, dialogHandler] = useDialog({
    className: `md:max-w-md`,
    children: () => {
      return (
        <div>
          <Slider
            className="w-full"
            slidesPerView={1}
            spaceBetween={10}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              768: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
            }}
          >
            {Slides}
          </Slider>
        </div>
      );
    },
  });

  useEffect(() => {
    if (showWelcomeOnboarding) {
      const timer = setTimeout(() => {
        setShowWelcomeOnboarding(false);
        dialogHandler.show();
      }, 1500);
      return () => clearTimeout(timer);
    }
  }, [
    dialogHandler,
    pathname,
    searchParams,
    setShowWelcomeOnboarding,
    showWelcomeOnboarding,
  ]);

  return Dialog;
};

export default UserOnboarding;
